import React from 'react'
import UtilityHeader from './UtilityHeader'
import NavHeader from './NavHeader'
import Footer from './Footer'
import { AppWrapperContainer, AppWrapperContentContainer } from './styled'
import { useSiteMetaQuery, SiteMetaQuery } from 'shared/schema'
import Header from 'src/components/Header'
import { useRoutes, Navigate, matchRoutes, useLocation } from 'react-router-dom'
import _ from 'lodash'

import HomeRoute from 'src/routes/home'
import UsersRoute from 'src/routes/users'
import DepartmentRoute from 'src/routes/department'
import Directory from 'src/routes/directory'
import Discussions from 'src/routes/discussions'
import { Route as WIPRoute } from 'src/components/wip'

import { useSearch } from 'src/components/Search'
import ResourceDialog, { useResource } from 'src/components/Resource'
import SearchResults from 'src/components/Search/Results'
import CalendarRoute from 'src/routes/calendar'
import GalleryRoute from 'src/routes/gallery'
import NewsAndAnnouncements from 'src/routes/news'
import OrgChart from 'src/routes/org-chart'
import AdminRoute from 'src/routes/admin'
import HRRoute from 'src/routes/hr'
import ITHelp from 'src/components/ITHelp'

type SiteContext = {
	siteMeta: SiteMetaQuery
}
const SiteContext = React.createContext<SiteContext>(null)

function AppWrapper() {
	const { loading, data } = useSiteMetaQuery()
	const location = useLocation()
	const { searchOpen } = useSearch()
	const { resourceOpen } = useResource()

	// eslint-disable-next-line prefer-const
	let currentParams

	const routes = [
		{ name: '', path: '*', element: <Navigate to="/home" /> },
		{ name: 'Home', hideTitle: true, path: '/home', element: <HomeRoute /> },
		{ name: 'Users', path: '/users', element: <UsersRoute /> },
		{ name: 'News', path: '/news/*', element: <NewsAndAnnouncements /> },
		{ name: 'Company Directory', path: '/company-directory', element: <Directory /> },
		{ name: 'Org Chart', path: '/org-chart', element: <OrgChart /> },
		{ name: 'Discussions', path: '/discussions/*', element: <Discussions /> },
		{ name: 'Gallery', path: '/gallery/*', element: <GalleryRoute /> },
		{ name: 'Calendar', path: '/calendar/*', element: <CalendarRoute /> },
		{ name: 'Feedback', path: '/feedback', element: <WIPRoute /> },
		{ name: 'Etiquette', path: '/etiquette', element: <WIPRoute /> },
		{
			name: 'Admin',
			caseSensitive: false,
			path: '/admin/*',
			element: <AdminRoute />
		},
		{
			name: 'HR Tools',
			caseSensitive: false,
			path: '/hr/*',
			element: <HRRoute />
		},
		{
			get name() {
				return data.departments.find((department) => department.id === +currentParams.departmentId)?.title
			},
			get header() {
				return data.departments.find((department) => department.id === +currentParams.departmentId)?.masthead
					?.publicUrl
			},
			get hideTitle() {
				return !!data.departments.find((department) => department.id === +currentParams.departmentId)?.logo
			},
			caseSensitive: false,
			path: '/departments/:departmentId/*',
			element: <DepartmentRoute />
		}
	]
	const authRoutes = useRoutes(routes)

	if (loading || !data) {
		return null
	}
	const match = (matchRoutes(routes, location)[0] as any) ?? {}
	const currentRoute = match.route
	currentParams = match.params

	const mastheadUrl =
		currentRoute.header ??
		data.pages.find((page) => page.slug === currentRoute.path.split('/')[1])?.masthead?.publicUrl

	const siteContext = {
		siteMeta: {
			...data,
			departments: _.orderBy(data.departments, 'title')
		}
	}

	return (
		<SiteContext.Provider value={siteContext}>
			<AppWrapperContainer>
				<UtilityHeader />
				<NavHeader />
				{!currentRoute.hideHeader && (
					<Header
						imageUrl={mastheadUrl}
						title={currentRoute.name}
						hideTitle={currentRoute.hideTitle ?? false}
					/>
				)}
				<AppWrapperContentContainer>
					{authRoutes}
					{searchOpen && <SearchResults />}
					{resourceOpen && <ResourceDialog />}
				</AppWrapperContentContainer>
				<Footer />
				<ITHelp />
			</AppWrapperContainer>
		</SiteContext.Provider>
	)
}

export function useSiteContext() {
	return React.useContext(SiteContext)
}

export default AppWrapper
