import React from 'react'
import dayjs from 'dayjs'
import filesize from 'filesize'
import { useResource } from 'src/components/Resource'
import { compact } from 'lodash'
import { NavLink } from 'react-router-dom'
import { Trash as TrashIcon, Plus as PlusIcon } from 'src/icons'
import _ from 'lodash'
import { useSiteMetaQuery, useGetPresignedUploadMutation } from 'shared/schema'

import { LinkCardSize, ILinkCardProps, CardColor } from './types'
import {
	NewsIcon,
	DiscussionIcon,
	LinkCardWrapper,
	ItemLink,
	ItemSubtitle,
	FileIcon,
	PersonIcon,
	TitleWrapper,
	TypeBadge,
	ProfilePictureIcon,
	Link,
	ActionIcon,
	ActionIconWithPadding,
	CommentNavLink
} from './styled'
import Favoriteable from '../Favoriteable'

function getIcon(
	type: string,
	$displaySize: LinkCardSize,
	iconColor: CardColor,
	ext?: string,
	profilePhotoUrl?: string
) {
	switch (type) {
		case 'discussions':
			return <DiscussionIcon $displaySize={$displaySize} $iconColor={iconColor} />
		case 'bulletins':
			return <NewsIcon $displaySize={$displaySize} $iconColor={iconColor} />
		case 'resources':
			return <FileIcon $displaySize={$displaySize} $iconColor={iconColor} ext={ext} />
		case 'users':
			return profilePhotoUrl ? (
				<ProfilePictureIcon $profilePhotoUrl={profilePhotoUrl} />
			) : (
				<PersonIcon $displaySize={$displaySize} $iconColor={iconColor} />
			)
		default:
			return null
	}
}
// const getSignedUrl=async(_type: string, _fileName: string)=>{
// 	const [getPresignedUpload] = useGetPresignedUploadMutation()
// 	const result = await getPresignedUpload({
// 		variables: {
// 			type:_type,
// 			fileName:_fileName,
// 			isPublic: true
// 		}
// 	})
// 	return result.data.getPresignedUpload
// }
	

function LinkCard(props: ILinkCardProps) {
	if (!props.data) {
		return null
	}
	const { data: siteMetaData } = useSiteMetaQuery()
	const { setResource, setResourceOpen } = useResource()

	let to = ''
	let onClick: any = props.onClick
	const onResourceContextClick: any = props.onResourceContextClick
	let title: string
	let subtitle: string[] = []
	let badgeText: string
	let badgeColor: CardColor = 'neonGreen'
	let iconColor: CardColor = 'neonGreen'

	const resourceContext = _.get(props.data, 'departmentContentsResources.departmentContent')
	let resourceContextDepartmentTitle = null
	if (resourceContext && onResourceContextClick) {
		resourceContextDepartmentTitle = siteMetaData.departments.find((d) => d.id === resourceContext.departmentId)
			?.title
	}

	switch (props.data.__typename) {
		case 'bulletins':
			title = props.data.title
			subtitle = compact([
				dayjs(props.data.createdAt).format('ll'),
				props.data.author && `by ${props.data.author.firstName} ${props.data.author.lastName}`
			])

			to =
				props.departmentId > 0
					? `/departments/${props.departmentId}/news/${props.data.id}`
					: `/news/${props.modelId}`
			badgeText = 'Announcement'
			badgeColor = 'red'
			iconColor = 'red'
			break
		case 'discussions':
			title = props.data.title
			badgeText = 'Discussion'
			badgeColor = 'teal'
			subtitle = compact([
				dayjs(props.data.createdAt).format('ll'),
				props.data.author && `by ${props.data.author.firstName} ${props.data.author.lastName}`
			])
			to =
				props.departmentId > 0 || props.data.departmentId > 0
					? `/departments/${props.departmentId || props.data.departmentId}/discussions/${props.data.id}`
					: `/discussions/${props.data.id}`
			break
		case 'resources':
			if (props.data.file) {
				console.log('props.data.file', props.data.file)
				title = props.data.title
				badgeText = 'Resource'
				subtitle = [
					dayjs(props.data.createdAt).format('lll'),
					props.data.file.type,
					filesize(props.data.file.size)
				]
				onClick = () => {
					setResourceOpen(true)
					setResource(props.data)
				}
			}
			break
		case 'users':
			badgeText = 'User'
			badgeColor = 'royalBlue'
			iconColor = 'lightestGray'
			title = `${props.data.firstName} ${props.data.lastName}`
			subtitle = compact([props.data?.department?.title, props.data?.jobTitle])
			break
	}

	const Icon = getIcon(
		props.data.__typename,
		props.$displaySize ?? 'med',
		iconColor,
		props.data.__typename === 'resources' ? props?.data?.file?.key.split('.').pop() : null,
		props.data.__typename === 'users' ? props?.data?.profilePhotoUrl : null
	)

	if (!title) return null

	return (
		<LinkCardWrapper>
			{Icon}
			<div>
				<TitleWrapper $hasSubtitle={subtitle?.length > 0}>
					<ItemLink to={to} $displaySize={props.$displaySize ?? 'med'} onClick={onClick}>
						{title}
					</ItemLink>
					{props.showTypeBadge && <TypeBadge $color={badgeColor}>{badgeText}</TypeBadge>}
				</TitleWrapper>
				<ItemSubtitle>
					{subtitle.join(' • ')}
					{props.data.__typename === 'resources' && resourceContextDepartmentTitle && (
						<>
							{' • '}
							<span
								style={{ cursor: 'pointer', color: '#EA6C37' }}
								onClick={() => {
									onResourceContextClick(resourceContext)
								}}
							>
								{resourceContextDepartmentTitle} → {resourceContext.title}
							</span>
						</>
					)}
					{props.data.__typename === 'discussions' && props.showSubtitleActions && (
						<>
							{' • '}
							<CommentNavLink to={`${props.data.id}`}>
								{props.data.comments.length} comments
							</CommentNavLink>
						</>
					)}
					{props.data.__typename === 'discussions' && props.showSubtitleActions && (
						<>
							{' • '} <Favoriteable data={props.data} />
						</>
					)}
				</ItemSubtitle>
				{props.editActions?.remove && (
					<ItemSubtitle>
						{props.data.__typename === 'resources' && (
							<Link onClick={props.editActions.remove}>
								<ActionIconWithPadding as={TrashIcon} />
								Remove
							</Link>
						)}
					</ItemSubtitle>
				)}
				{props.editActions?.add && (
					<ItemSubtitle>
						{props.data.__typename === 'resources' && (
							<Link onClick={props.editActions.add}>
								<ActionIcon as={PlusIcon} />
								Add
							</Link>
						)}
					</ItemSubtitle>
				)}
			</div>
		</LinkCardWrapper>
	)
}

export default LinkCard
