import React, { useState, useEffect } from 'react';
import ITHelpButton from './it-help.png'

const ITHelp: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const onButtonClick = () => {
      window.open('https://ithelp.nibbi.com', '_blank');
  };

  const handleScroll = () => {
    if (window.scrollY > 300) setIsScrolled(true);
    else setIsScrolled(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000 }}>
        <button onClick={onButtonClick} className="rounded-full shadow-lg" style={{backgroundColor:'transparent',border:'none'}}>
          <img src={ITHelpButton} alt="IT Help" style={{borderRadius: '50%'}} width={75} height={75} />
        </button>
    </div>
  );
};

export default ITHelp;